<template>
  <Modal title="Invite QR Code" v-bind="$attrs">
    <div class="md:flex">
      <div class="flex-shrink-0">
        <img :src="`${$apiUrl}go/collections/${this.hashid}/invite/qr`" height="160" width="160" />
      </div>
      <div class="space-y-6 md:ml-10">
        <p class="mt-6 text-lg md:mt-0">Share your content experience invite effortlessly using your unique QR code.</p>
        <p>
          <Button
            as="a"
            :href="`${$apiUrl}go/collections/${this.hashid}/invite/qr?download`"
            type="primary"
            prepend-icon="download"
            >Download QR code</Button
          >
        </p>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  props: {
    hashid: {
      type: String,
      required: true
    }
  }
}
</script>
